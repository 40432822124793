<template>
  <section v-if="childDataLoaded">
    <div class="title-wrapper">
      <header class="header">
        <h1 class="title">{{ pageComponents.page_title }}</h1>
        <p v-if="pageComponents.page_title" class="pre-title">{{ $t('legal') }}</p>
      </header>
      <nav v-if="showSubNav" class="subnav">
        <btn v-for="(navItem, idx) of getSubNavArr"
            :key="idx"
            :text="navItem.label"
            :href="createUnlocalizedPathFromSlug(navItem.destination)"
            :customClass="currentSubPg(navItem.destination)"
        >
        </btn>
      </nav>
    </div>
    <div class="page-container wysiwyg" v-html="pageComponents.wysiwyg"></div>
  </section>
</template>

<script>
import { butter } from '@/buttercms';
import Btn from '@/components/basic/Btn';

export default {
  name: 'Legal',
  components: {
    Btn,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      name: '',
      childDataLoaded: false,
      thisPage: {
        fields: {
          legal_page_template: {},
        },
      },
      thisSubNav: {
        sub_nav: [
          { sub_nav_item: [] }
        ],
      },
    };
  },
  computed: {
    showSubNav() {
      return !this.thisPage.fields.legal_page_template.hide_subnav;
    },
    getSubNavArr() {
      return this.thisSubNav.sub_nav[0].sub_nav_item;
    },
    locale() {
      return this.$route.params.locale;
    },
    pageComponents() {
      return this.thisPage.fields.legal_page_template;
    },
  },
  methods: {
    fetchSubNav() {
      butter.content.retrieve(['sub_nav'], {
        'fields.sub_nav_label': 'legal page subnav',
        locale: this.locale,
      })
        .then((resp) => {
          this.thisSubNav = resp.data.data;
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
    fetchCms(dynamicParam) {
      butter.page.retrieve('legal', (dynamicParam || this.slug), { locale: this.locale })
        .then((resp) => {
          this.thisPage = resp.data.data;
          this.childDataLoaded = true;
        })
        .catch((e) => {
          console.warn(e);
          this.$router.push({ name: 'home' });
        });
    },
    currentSubPg(curPath) {
      return (curPath == this.$route.params.slug) ? 'active' : '';
    },
    createUnlocalizedPathFromSlug(slugPart) {
      return `${this.$route.name}/${slugPart}`;
    },
  },
  created() {
    this.fetchCms();
    this.fetchSubNav();
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchCms(to.params.slug);
    this.fetchSubNav();
    next();
  },
};
</script>

<style lang="scss">
  .content.legal {
    padding: 0;
  }
</style>
<style scoped lang="scss">
  .header {
    display: flex;
    flex-direction: column;
    color: $white;
    margin-bottom: auto;
    margin-top: 3rem;

    @include bp-medium {
      margin-bottom: 2rem;
    }
  }

  .pre-title {
    font-weight: 700;
    order: 1;
  }

  .title {
    font-weight: normal;
    order: 2;
  }

  .title-wrapper {
    width: 100vw;
    max-height: 30rem;
    min-height: 22.5rem;
    background-color: #007aff;
    padding-left: $responsive-padding;
    padding-right: $responsive-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @include bp-large {
      padding: 1rem $responsive-padding-desktop;
    }
  }

  .subnav {
    position: absolute;
    bottom: 0.5rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 0;
    grid-template-columns: auto;
    grid-template-rows: auto;

    @include bp-medium {
      grid-template-columns: repeat(2, auto);
    }

    @include bp-mediumlarge {
      grid-template-columns: repeat(4, auto);
    }

    a {
      opacity: 0.5;

      &.active {
        opacity: 1;
      }

      ::v-deep .btn__content {
        font-weight: 700;
      }
    }
  }

  .page-container {
    padding: 5rem $responsive-padding-desktop;

    ::v-deep ul {
      margin-left: 1.5rem;
      li:before {
        margin-right: 0.7rem;
        margin-left: -1rem;
      }
    }
  }
</style>
